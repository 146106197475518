<template>
  <div class="lesson">
    <div
      class="student-groups collapsible"
      v-for="group in groups"
      :key="group.id"
    >
      <student-group-component
        :group="group"
        :student="student"
        @editGroupHandle="editGroupHandle"
        @removeStudent="removeStudentHandle"
        @moveStudent="moveStudentHandle"
      />
    </div>
    <div class="collapsible">
      <div class="tutor-lesson-header">
        <div class="collapsible__button" @click="tutorOpened = !tutorOpened">
          <vs-icon
            icon="chevron_right"
            class="small-button collapsible__button__arrow"
            color="dark"
          ></vs-icon>
          <div class="title">Tutor Lessons</div>
          <div class="tutor-lesson-manage">
            <vs-button
              class="add-tutor-lesson-button manage-student-button"
              icon="add"
              type="flat"
              @click="popupActive = !popupActive"
              >Add tutor lesson</vs-button
            >
          </div>
        </div>
        <div class="collapsible_content" v-if="tutorOpened">
          <div class="tutor-lesson__items">
            <tutor-attendance />
          </div>
          <div class="tutor-lesson-orders" v-if="$can('manage', 'payments')">
            <OrdersWrapper
              :student="student"
              orderType="lesson"
              :schoolYearId="schoolYearId"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="tutor-lesson">
    
    </div> -->
    <div v-if="popupActive">
      <vs-popup
        class="popup__lessons"
        classContent=""
        title="Add tutor lesson"
        :active.sync="popupActive"
      >
        <LessonForm
          v-if="popupActive"
          @saveLesson="addTutorLessonHandler"
          :groupLesson="{ student }"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LessonForm from '@/components/lessons/generalLessonForm'
import TutorAttendance from './tutorAttendance.vue'
import OrdersWrapper from '@/components/students/orders/wrapper'
import StudentGroupComponent from '@/components/students/studentGroupComponent.vue'

export default {
  title: 'SE-Student Tutor lessons',
  props: ['schoolYearId', 'groups'],
  data() {
    return {
      popupActive: false,
      tutorOpened: false,
    }
  },
  computed: {
    ...mapState({
      student: state => state.students.singleStudent,
      tutorLessons: state => state.lessons.tutorLessonsByStudent,
      teachers: state => state.teachers.teachers,
      rooms: state => state.rooms.rooms,
      students: state => state.students.students,
      years: state => state.years.years,
    }),
    lessonsByMonth: function() {
      const lessons = {}
      this.tutorLessons.forEach(l => {
        const formatedDate = this.$moment(l.lesson_time * 1000).format(
          'MMMM YYYY'
        )
        if (lessons[formatedDate] !== undefined) {
          lessons[formatedDate].push(l)
        } else {
          lessons[formatedDate] = [l]
        }
      })
      return lessons
    },
  },
  components: {
    TutorAttendance,
    LessonForm,
    OrdersWrapper,
    StudentGroupComponent,
  },
  methods: {
    addTutorLessonHandler: function(data) {
      data = {
        ...data,
        lesson_time: data.date,
        name: data.lessonName,
        lesson_type: 'tutor',
        teacher_id: data.teacher,
        room_id: data.room,
        students: [data.student.id],
      }

      this.$store
        .dispatch('lessons/saveTutorLessonAction', { data })
        .then(() => {
          this.updateLessons()
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Error',
            text: `${e}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    openEditLesson: function(l) {
      this.editLesson = l
      this.editPopupActive = true
    },
    updateLessons: function() {
      const student_id = this.student.id
      const schoolyear_id = this.schoolYearId
      this.$store
        .dispatch('lessons/getTutorLessonsByStudentAction', {
          student_id,
          schoolyear_id,
        })
        .then(() => {
          this.popupActive = false
          this.getOrders()
        })
    },
    getOrders: function() {
      const student = this.student.id
      this.$store.dispatch('orders/getOrdersTutorByStudentAction', {
        student,
        schoolyear_id: this.schoolYearId,
      })
    },
    editGroupHandle: function(group) {
      this.$emit('editGroupHandle', group)
    },
    removeStudentHandle: function(group) {
      this.$emit('removeStudentHandle', group)
    },
    moveStudentHandle: function(group) {
      this.$emit('moveStudentHandle', group)
    },
  },
  created: async function() {
    if (!this.teachers.length) {
      await this.$store.dispatch('teachers/getTeachersAction')
    }
    if (!this.rooms.length) {
      await this.$store.dispatch('rooms/getRoomsAction')
    }
    if (!this.students.length) {
      await this.$store.dispatch('students/getStudentsAction')
    }
    if (!this.schoolYearId) {
      await this.$store.dispatch('years/getYearsAction')
    }
    this.getOrders()
  },
  watch: {
    schoolYearId: function() {
      this.getOrders()
    },
  },
}
</script>

<style lang="scss">
.tutor-lesson {
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  .tutor-lesson__items {
    & > div {
      margin: 15px 0;
      border-bottom: 1px solid #ccc;
    }
    .collapsible_content {
      padding-left: 45px;
    }
    .collapsible__button {
      flex: 1;
      max-width: 400px;
    }
    .tutor-lesson__month {
      padding: 7px 0;
    }
    .tutor-lesson__item {
      .tutor-lesson-header {
        display: flex;
        flex-flow: row wrap;
      }
    }

    .tutor-lesson-header {
      .lesson-dates {
        font-size: 0.8em;
        padding: 3px 10px;
        box-shadow: 0 0 1px 2px #eee;
        border-radius: 10px;
        margin-left: 20px;
      }
    }
    .tutor-lesson__data {
      display: flex;
      gap: 10px;
      position: relative;
      &::before {
        content: '';
        width: 2px;
        background-color: #aaa;
        height: 60px;
        z-index: -1;
        position: absolute;
        bottom: 95%;
        display: block;
        left: 10px;
      }
    }
    .tutor-lesson-comment {
      max-width: 70%;
    }
    .tutor-lesson-orders {
      margin-top: 25px;
    }
    .tutor-lesson__manage {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: 25px;
      & > * {
        margin-right: 15px;
      }
      .vs-button {
        float: none !important;
        i {
          font-size: 12px;
        }
      }
      .tutor-lesson__attendance {
        padding: 15px 25px;
        border: 1px solid #000;
      }
    }
  }
}
</style>
